@font-face {
    font-family: "flaticon_dynexhub";
    src: url("./flaticon_dynexhub.ttf?13d10b4317c53fe81f2940ef806aa79f") format("truetype"),
url("./flaticon_dynexhub.woff?13d10b4317c53fe81f2940ef806aa79f") format("woff"),
url("./flaticon_dynexhub.woff2?13d10b4317c53fe81f2940ef806aa79f") format("woff2"),
url("./flaticon_dynexhub.eot?13d10b4317c53fe81f2940ef806aa79f#iefix") format("embedded-opentype"),
url("./flaticon_dynexhub.svg?13d10b4317c53fe81f2940ef806aa79f#flaticon_dynexhub") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_dynexhub !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-shopping-cart:before {
    content: "\f101";
}
.flaticon-shopping-cart-1:before {
    content: "\f102";
}
.flaticon-shopping-cart-2:before {
    content: "\f103";
}
.flaticon-bag:before {
    content: "\f104";
}
.flaticon-trolley:before {
    content: "\f105";
}
.flaticon-shopping-bag:before {
    content: "\f106";
}
.flaticon-shopping-cart-3:before {
    content: "\f107";
}
.flaticon-add-cart:before {
    content: "\f108";
}
.flaticon-add-to-cart:before {
    content: "\f109";
}
.flaticon-shopping-cart-5:before {
    content: "\f10a";
}
.flaticon-folder:before {
    content: "\f10b";
}
.flaticon-folder-management:before {
    content: "\f10c";
}
.flaticon-folder-2:before {
    content: "\f10d";
}
.flaticon-file-1:before {
    content: "\f10e";
}
.flaticon-folder-3:before {
    content: "\f10f";
}
.flaticon-folder-4:before {
    content: "\f110";
}
.flaticon-file-2:before {
    content: "\f111";
}
.flaticon-home:before {
    content: "\f112";
}
.flaticon-home-1:before {
    content: "\f113";
}
.flaticon-home-2:before {
    content: "\f114";
}
.flaticon-home-3:before {
    content: "\f115";
}
.flaticon-note:before {
    content: "\f116";
}
.flaticon-list:before {
    content: "\f117";
}
.flaticon-list-1:before {
    content: "\f118";
}
.flaticon-shopping-list:before {
    content: "\f119";
}
.flaticon-clipboard:before {
    content: "\f11a";
}
.flaticon-document:before {
    content: "\f11b";
}
.flaticon-risk-management:before {
    content: "\f11c";
}
.flaticon-checklist:before {
    content: "\f11d";
}
.flaticon-checklist-1:before {
    content: "\f11e";
}
.flaticon-document-1:before {
    content: "\f11f";
}
.flaticon-checklist-2:before {
    content: "\f120";
}
.flaticon-to-do-list:before {
    content: "\f121";
}
.flaticon-google-drive:before {
    content: "\f122";
}
.flaticon-driving-license:before {
    content: "\f123";
}
.flaticon-trim:before {
    content: "\f124";
}
.flaticon-video-editing:before {
    content: "\f125";
}
.flaticon-notepad:before {
    content: "\f126";
}
.flaticon-edit:before {
    content: "\f127";
}
.flaticon-edit-1:before {
    content: "\f128";
}
.flaticon-user:before {
    content: "\f129";
}
.flaticon-edit-2:before {
    content: "\f12a";
}
.flaticon-vector:before {
    content: "\f12b";
}
.flaticon-magic-wand:before {
    content: "\f12c";
}
.flaticon-search-interface-symbol:before {
    content: "\f12d";
}
.flaticon-search:before {
    content: "\f12e";
}
.flaticon-loupe:before {
    content: "\f12f";
}
.flaticon-loupe-1:before {
    content: "\f130";
}
.flaticon-magnifier:before {
    content: "\f131";
}
.flaticon-magnifying-glass:before {
    content: "\f132";
}
.flaticon-search-1:before {
    content: "\f133";
}
.flaticon-data:before {
    content: "\f134";
}
.flaticon-data-collection:before {
    content: "\f135";
}
.flaticon-monitor:before {
    content: "\f136";
}
.flaticon-dashboard:before {
    content: "\f137";
}
.flaticon-analysis:before {
    content: "\f138";
}
.flaticon-database:before {
    content: "\f139";
}
.flaticon-graph:before {
    content: "\f13a";
}
.flaticon-analysis-1:before {
    content: "\f13b";
}
.flaticon-database-1:before {
    content: "\f13c";
}
.flaticon-secure-data:before {
    content: "\f13d";
}
.flaticon-binary:before {
    content: "\f13e";
}
.flaticon-data-1:before {
    content: "\f13f";
}
.flaticon-data-analysis:before {
    content: "\f140";
}
.flaticon-personal-information:before {
    content: "\f141";
}
.flaticon-data-processing:before {
    content: "\f142";
}
.flaticon-data-security:before {
    content: "\f143";
}
.flaticon-request:before {
    content: "\f144";
}
.flaticon-search-2:before {
    content: "\f145";
}
.flaticon-request-1:before {
    content: "\f146";
}
.flaticon-data-science:before {
    content: "\f147";
}
.flaticon-user-interface:before {
    content: "\f148";
}
.flaticon-statistics:before {
    content: "\f149";
}
.flaticon-data-management:before {
    content: "\f14a";
}
.flaticon-source:before {
    content: "\f14b";
}
.flaticon-database-2:before {
    content: "\f14c";
}
.flaticon-secure:before {
    content: "\f14d";
}
.flaticon-layers:before {
    content: "\f14e";
}
.flaticon-community:before {
    content: "\f14f";
}
.flaticon-team:before {
    content: "\f150";
}
.flaticon-human-resources:before {
    content: "\f151";
}
.flaticon-user-1:before {
    content: "\f152";
}
.flaticon-community-1:before {
    content: "\f153";
}
.flaticon-user-2:before {
    content: "\f154";
}
.flaticon-user-3:before {
    content: "\f155";
}
.flaticon-group:before {
    content: "\f156";
}
.flaticon-group-1:before {
    content: "\f157";
}
.flaticon-calendar:before {
    content: "\f158";
}
.flaticon-event:before {
    content: "\f159";
}
.flaticon-confetti:before {
    content: "\f15a";
}
.flaticon-email:before {
    content: "\f15b";
}
.flaticon-phone-call:before {
    content: "\f15c";
}
.flaticon-blockchain:before {
    content: "\f15d";
}
.flaticon-digital-currency:before {
    content: "\f15e";
}
.flaticon-blockchain-1:before {
    content: "\f15f";
}
.flaticon-blockchain-2:before {
    content: "\f160";
}
.flaticon-teamwork:before {
    content: "\f161";
}
.flaticon-domain-registration:before {
    content: "\f162";
}
.flaticon-partnership:before {
    content: "\f163";
}
.flaticon-team-1:before {
    content: "\f164";
}
.flaticon-network:before {
    content: "\f165";
}
.flaticon-teamwork-1:before {
    content: "\f166";
}
.flaticon-delete:before {
    content: "\f167";
}
.flaticon-delete-1:before {
    content: "\f168";
}
.flaticon-check:before {
    content: "\f169";
}
.flaticon-immigration:before {
    content: "\f16a";
}
.flaticon-xml:before {
    content: "\f16b";
}
.flaticon-xml-1:before {
    content: "\f16c";
}
.flaticon-file-3:before {
    content: "\f16d";
}
.flaticon-mic:before {
    content: "\f16e";
}
.flaticon-input:before {
    content: "\f16f";
}
.flaticon-invoice:before {
    content: "\f170";
}
.flaticon-invoice-1:before {
    content: "\f171";
}
.flaticon-bill:before {
    content: "\f172";
}
.flaticon-bill-1:before {
    content: "\f173";
}
.flaticon-plus:before {
    content: "\f174";
}
.flaticon-minus-sign:before {
    content: "\f175";
}
.flaticon-turn-off:before {
    content: "\f176";
}
.flaticon-switch:before {
    content: "\f177";
}
.flaticon-log-out:before {
    content: "\f178";
}
.flaticon-lightbulb-1:before {
    content: "\f179";
}
.flaticon-printer:before {
    content: "\f17a";
}
.flaticon-printer-1:before {
    content: "\f17b";
}
.flaticon-data-2:before {
    content: "\f17c";
}
.flaticon-big-data:before {
    content: "\f17d";
}
.flaticon-risk-management-1:before {
    content: "\f17e";
}
.flaticon-calendar-1:before {
    content: "\f17f";
}
.flaticon-request-2:before {
    content: "\f180";
}
.flaticon-calendar-2:before {
    content: "\f181";
}
.flaticon-organizer:before {
    content: "\f182";
}
.flaticon-events:before {
    content: "\f183";
}
.flaticon-dots:before {
    content: "\f184";
}
.flaticon-option:before {
    content: "\f185";
}
.flaticon-settings:before {
    content: "\f186";
}
.flaticon-cog-wheel-silhouette:before {
    content: "\f187";
}
.flaticon-bell:before {
    content: "\f188";
}
.flaticon-notification:before {
    content: "\f189";
}
.flaticon-notification-1:before {
    content: "\f18a";
}
.flaticon-notification-2:before {
    content: "\f18b";
}
.flaticon-notification-3:before {
    content: "\f18c";
}
.flaticon-bin:before {
    content: "\f18d";
}
.flaticon-time:before {
    content: "\f18e";
}
.flaticon-files:before {
    content: "\f18f";
}
.flaticon-list-2:before {
    content: "\f190";
}
.flaticon-checklist-3:before {
    content: "\f191";
}
.flaticon-checklist-4:before {
    content: "\f192";
}
.flaticon-filter-filled-tool-symbol:before {
    content: "\f193";
}
.flaticon-filter:before {
    content: "\f194";
}
.flaticon-filter-1:before {
    content: "\f195";
}
.flaticon-heart:before {
    content: "\f196";
}
.flaticon-black-circle:before {
    content: "\f197";
}
.flaticon-rotated-right-arrow-with-broken-line:before {
    content: "\f198";
}
.flaticon-grid:before {
    content: "\f199";
}
.flaticon-circle-loading:before {
    content: "\f19a";
}
.flaticon-rec:before {
    content: "\f19b";
}
.flaticon-upload:before {
    content: "\f19c";
}
.flaticon-cloud-computing:before {
    content: "\f19d";
}
.flaticon-cloud-computing-1:before {
    content: "\f19e";
}
.flaticon-upload-1:before {
    content: "\f19f";
}
.flaticon-gallery:before {
    content: "\f1a0";
}
.flaticon-photo:before {
    content: "\f1a1";
}
.flaticon-price-tag:before {
    content: "\f1a2";
}
.flaticon-curved-arrow:before {
    content: "\f1a3";
}
.flaticon-upload-2:before {
    content: "\f1a4";
}
.flaticon-left-arrow:before {
    content: "\f1a5";
}
.flaticon-right-chevron:before {
    content: "\f1a6";
}
.flaticon-right-chevron-1:before {
    content: "\f1a7";
}
.flaticon-left-chevron:before {
    content: "\f1a8";
}
.flaticon-left-chevron-1:before {
    content: "\f1a9";
}
.flaticon-cancel:before {
    content: "\f1aa";
}
.flaticon-remove:before {
    content: "\f1ab";
}
.flaticon-right-arrow-1:before {
    content: "\f1ac";
}
.flaticon-left-arrow-1:before {
    content: "\f1ad";
}
.flaticon-back:before {
    content: "\f1ae";
}
.flaticon-arrow:before {
    content: "\f1af";
}
.flaticon-flick-to-left:before {
    content: "\f1b0";
}
.flaticon-close:before {
    content: "\f1b1";
}
.flaticon-close-1:before {
    content: "\f1b2";
}
.flaticon-close-2:before {
    content: "\f1b3";
}
.flaticon-save-instagram:before {
    content: "\f1b4";
}
.flaticon-bookmark:before {
    content: "\f1b5";
}
.flaticon-diskette:before {
    content: "\f1b6";
}
.flaticon-save-time:before {
    content: "\f1b7";
}
.flaticon-file-4:before {
    content: "\f1b8";
}
.flaticon-send:before {
    content: "\f1b9";
}
.flaticon-email-1:before {
    content: "\f1ba";
}
.flaticon-send-1:before {
    content: "\f1bb";
}
.flaticon-attached:before {
    content: "\f1bc";
}
.flaticon-attach:before {
    content: "\f1bd";
}
.flaticon-attach-1:before {
    content: "\f1be";
}
.flaticon-api:before {
    content: "\f1bf";
}
.flaticon-api-1:before {
    content: "\f1c0";
}
.flaticon-api-2:before {
    content: "\f1c1";
}
.flaticon-api-3:before {
    content: "\f1c2";
}
.flaticon-download:before {
    content: "\f1c3";
}
.flaticon-tag:before {
    content: "\f1c4";
}
.flaticon-relation:before {
    content: "\f1c5";
}
.flaticon-social-network:before {
    content: "\f1c6";
}
.flaticon-group-2:before {
    content: "\f1c7";
}
.flaticon-group-3:before {
    content: "\f1c8";
}
.flaticon-attached-1:before {
    content: "\f1c9";
}
.flaticon-attachment:before {
    content: "\f1ca";
}
.flaticon-tasks:before {
    content: "\f1cb";
}
.flaticon-time-management:before {
    content: "\f1cc";
}
.flaticon-inventory:before {
    content: "\f1cd";
}
.flaticon-desk:before {
    content: "\f1ce";
}
.flaticon-table:before {
    content: "\f1cf";
}
.flaticon-table-1:before {
    content: "\f1d0";
}
.flaticon-upload-3:before {
    content: "\f1d1";
}
.flaticon-menu-1:before {
    content: "\f1d2";
}
.flaticon-upload-4:before {
    content: "\f1d3";
}
.flaticon-shared-vision:before {
    content: "\f1d4";
}
.flaticon-downloads:before {
    content: "\f1d5";
}
.flaticon-up-loading:before {
    content: "\f1d6";
}
.flaticon-api-4:before {
    content: "\f1d7";
}
.flaticon-table-2:before {
    content: "\f1d8";
}
.flaticon-download-1:before {
    content: "\f1d9";
}
.flaticon-upload-5:before {
    content: "\f1da";
}
.flaticon-upload-6:before {
    content: "\f1db";
}
.flaticon-add-to-cart-1:before {
    content: "\f1dc";
}
.flaticon-thumbs-up:before {
    content: "\f1dd";
}
.flaticon-thumbs-up-1:before {
    content: "\f1de";
}
.flaticon-comment:before {
    content: "\f1df";
}
.flaticon-chat:before {
    content: "\f1e0";
}
.flaticon-comment-1:before {
    content: "\f1e1";
}
.flaticon-document-2:before {
    content: "\f1e2";
}
.flaticon-file-5:before {
    content: "\f1e3";
}
.flaticon-paperwork:before {
    content: "\f1e4";
}
