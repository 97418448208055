@import "./mixin";
@import "../assets/font/common-font.scss";
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  // --primary: #00b0f0;
  // --primary: #e50010;
  // --primary: #0933CC;
  // --primary: #002d42;
  --primary: #1f2659;
  --primary-hover: #1f2659;
  --light-blue: #e7eefe;
  --pink: #fe19a1;
  // --secondary: #70aa26;
  --secondary: #62d84e;
  --light-green: #e0fff2;
  --secondary-hover: #000000;
  --secondary-hover-color: #e8fce3;
  --dark-gray: #707070;
  --cool-gray: #e4e4e4;
  --pantone-gray: #f5f5f5;
  --light-gray: #f7f8f9;
  --light-pink: #fff7f4;
  --grayish-red: #eeebe5;
  --warm-gray: #cfc6bb;
  --light-grayish-pink: #ffecf7;
  --black: #333;
  --full-black: #000;
  --white: #fff;
  --unread: #d6ebff;
  --gray: #e0dfdc;
  --danger: #ff4d4f;
  --white-smoke: #f5f5f5;
  --light-white-smoke: #f3f3f3;
  --success: #1a8d08;
  --green: #00ab66;
  --purple: #7e57c2;
  --scroll-border: #d3d3d3;
  --default-box-shadow: 0 2px 15px 0 #dcdcdc;
  --gradient-background: linear-gradient(to right, #f3b605 0%, #ff6b5e 100%);
  --padding-desktop: 60px 0;
  --padding-desktop-large: 90px 0;
  --border-color: #e7e7e7;
  --alice-blue: #f5fcff;
  --very-light-gray: #f9f9f9;
  --light-shade-gray: #ddd;
  --alice-light-blue: #ebebeb;
  --caribbean-green: #026ec1;
  --chinese-silver: #ccc;
  --shadow-color-1: rgba(42, 47, 69, 0.1);
  --shadow-color-2: rgba(42, 47, 69, 0.08);
  --shadow-color-3: rgba(0, 0, 0, 0.08);
  --shadow-depth-1: 0 2px 5px 0 var(--shadow-color-1), 0 3px 9px 0 var(--shadow-color-2), 0 1px 1.5px 0 var(--shadow-color-3);
  --layout-light-blue: #f2f3f3;
  --color-bg-title-layout: #edffc8;
  --sider-background: #f3f3fe;
  --sider-menu-selected: #c8ceed;
  // layout pannel colors
  --pannel-gray: #f7f7f7;

  // --pannel-gray: #f1f5f7;
  --pannel-header: #dbe0ff;

  // grid color combinations
  --grid-color1: #e0f1f7;
  --grid-color2: #d0cfe4;
  --grid-color3: #c3dfa2;
  --grid-color4: #fef5cd;

  // color shades
  --color-shade1: #1a8abf;
  --color-shade2: #3397c6;
  --color-shade3: #4da4cd;
  --color-shade4: #66b1d4;
  --color-shade5: #80bedc;
  --color-shade6: #99cbe3;
  --color-shade7: #b3d8ea;
  --color-shade8: #cce5f1;
  --color-shade9: #e6f2f8;

  // font-family
  // --Playfair: 'Playfair Display', serif;
  // --reglar-font-family: 'GilroyRegular', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  // --medium-font-family: 'GilroyMedium', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  // --bold-font-family: 'GilroyBold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  // --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --react-modal-background: rgba(41, 47, 76, 0.70);
  --dark-background-color: #f6f7fb;
  --application-border-color: #e6e9ef;
  --box-shadow-color: #e6e9ef;
  --secondary-background-color: #ffffff;
  --gradient-color: linear-gradient(67deg, #fff3ca 0%, #c9ebff 91%);

  // font-weight and line height mentions
  --font-weight-very-light: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-bold: 500;
  --font-size-10: 14px;
  --font-size-20: 14px;
  --font-size-30: 16px;
  --font-size-40: 18px;
  --font-size-50: 24px;
  --font-size-60: 30px;
  --font-line-height-10: 18px;
  --font-line-height-20: 24px;
  --font-line-height-30: 24px;
  --font-line-height-40: 24px;
  --font-line-height-50: 32px;
  --font-line-height-60: 42px;
  --font-size-h1: var(--font-size-60);
  --font-size-h2: var(--font-size-50);
  --font-size-h3: var(--font-size-50);
  --font-size-h4: var(--font-size-40);
  --font-size-h5: var(--font-size-30);
  --font-size-general-label: var(--font-size-20);
  --font-size-paragraph: var(--font-size-30);
  --font-size-subtext: var(--font-size-10);
  --font-line-height-h1: var(--font-line-height-60);
  --font-line-height-h2: var(--font-line-height-50);
  --font-line-height-h3: var(--font-line-height-50);
  --font-line-height-h4: var(--font-line-height-40);
  --font-line-height-h5: var(--font-line-height-30);
  --font-line-height-general-label: var(--font-line-height-20);
  --font-line-height-paragraph: var(--font-line-height-30);
  --font-line-height-subtext: var(--font-line-height-10);

  // box shadow
  --box-shadow-small: 0px 4px 8px rgba(0, 0, 0, 0.2);
  --box-shadow-medium: 0px 6px 20px rgba(0, 0, 0, 0.2);
  --box-shadow-large: 0px 15px 50px rgba(0, 0, 0, 0.3);

  --font-weight: var(--font-weight-normal);
  --font-family: Figtree, Roboto, Rubik, sans-serif;
  --title-font-family: Poppins, Roboto, Rubik, sans-serif;
  --font-h1: var(--font-weight-bold) var(--font-size-h1) / var(--font-line-height-h1) var(--title-font-family);
  --font-h2: var(--font-weight-bold) var(--font-size-h2) / var(--font-line-height-h2) var(--title-font-family);
  --font-h3: var(--font-weight-light) var(--font-size-h3) / var(--font-line-height-h3) var(--title-font-family);
  --font-h4: var(--font-weight-bold) var(--font-size-h4) / var(--font-line-height-h4) var(--title-font-family);
  --font-h5: var(--font-weight-bold) var(--font-size-h5) / var(--font-line-height-h5) var(--font-family);
  --font-general-label: var(--font-weight-normal) var(--font-size-general-label) / var(--font-line-height-general-label) var(--font-family);
  --font-paragraph: var(--font-weight-normal) var(--font-size-paragraph) / var(--font-line-height-paragraph) var(--font-family);
  --font-subtext: var(--font-weight-normal) var(--font-size-subtext) / var(--font-line-height-subtext) var(--font-family);

}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

strong {
  font-weight: 600;
}

body {
  counter-reset: section;
  // line-height: 160%;


}

// global style declaration for heading tags
a {
  color: var(--black);
}

h1 {
  font-weight: (--font-weight-bold);
  font-size: var(--font-size-h1);
  line-height: var(--font-line-height-h1);
  font-family: var(--title-font-family);
}

h2 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h2);
  line-height: var(--font-line-height-h2);
  font-family: var(--title-font-family);

}

h3 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h3);
  line-height: var(--font-line-height-h3);
  font-family: var(--title-font-family);

}

h4 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h4);
  line-height: var(--font-line-height-h4);
  font-family: var(--title-font-family);

}

h5 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-h5);
  font-family: var(--font-family);

}

h6 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h5);
  line-height: var(--font-line-height-h5);
  font-family: var(--font-family);
}

p {
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-paragraph);
  line-height: var(--font-line-height-paragraph);
  font-family: var(--font-family);

  &:last-child {
    margin-bottom: 0;
  }
}

body {
  font-family: var(--font-family);
  font-weight: var(--font-weight-normal);
  line-height: 1.5;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #333;
  scroll-behavior: smooth;
  font-feature-settings: unset;
  font-variant: normal;
}

a {
  text-decoration: none;
}

// webkit scrollbar starts
*::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;

  // -webkit-box-shadow: inset 0 0 6px var(--white-smoke);
}

*::-webkit-scrollbar {
  background-color: #fff;
  height: 6px;
  transition: visibility 0s 0.2s;
  width: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 0;

  // -webkit-box-shadow: inset 0 0 6px var(--white-smoke);
}

*::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-border);
}

// ant design customization
.ant-layout {
  background-color: var(--white);
  line-height: 1.5;
}

// .ant-pagination.ant-pagination-mini
// active pagination
.ant-pagination {
  &.ant-pagination-mini {

    .ant-pagination-prev,
    .ant-pagination-next {
      line-height: 6px;

      .ant-pagination-item-link {
        font-size: 16px;
        color: var(--primary);
      }
    }

    .ant-pagination-item {
      border-radius: 50px;
      min-width: 22px;
      height: 22px;
      margin: 0;
      line-height: 19px;

      &.ant-pagination-item-active,
      &.ant-pagination-item-active:hover {
        background: var(--secondary);
        border-color: var(--secondary);
        color: var(--white);
      }
    }
  }

  .ant-pagination-options {
    margin-left: 20px !important;

    .ant-select-selector {
      height: 29px !important;
      padding: 3px 7px !important;
      background: var(--primary);
      border-color: var(--primary);

      .ant-select-selection-item {
        color: var(--white);
      }
    }
  }

  .ant-select-arrow {
    color: var(--white);
  }


}

// ant design modal box
.ant-modal-mask {
  background: var(--react-modal-background);
}

.ant-modal-content {
  pointer-events: auto;
  box-shadow: 0 15px 50px -10px rgba(0, 0, 0, .3);
  border-radius: 16px;

  .ant-modal-close-x {
    .anticon {
      background: #d7d7d7;
      padding: 6px;
      border-radius: 50%;
      color: var(--black);
      transition: 0.4s;
      font-size: 13px;
      position: relative;
      top: -5px;

      &:hover {
        background: var(--danger);
        color: var(--white);
      }
    }
  }
}

.ant-modal-header {
  border-radius: 16px 16px 0 0;
}

.site-layout {
  min-height: 700px;

  .site-layout-background {
    // background-color: #f1f5f7;
    background-color: var(--white);
  }

  .content-pannel {
    // max-height: 81vh;
    overflow-y: auto;
    padding: 30px 40px;
    position: relative;
    background: var(--dark-background-color);

    @media(max-width: 900px) {
      padding: 20px;
    }
  }

  .filter-button {
    position: absolute;
    top: 14px;
    left: 0;

    @media(max-width: 900px) {
      &.close {
        left: 210px;
        z-index: 9;
      }
    }

    @media(max-width: 500px) {
      &.close {
        left: 210px;
        z-index: 9999;
        position: fixed;
        top: 143px;
      }
    }


    button {
      background: var(--primary);
      border: 0;
      border-radius: 0;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
      color: var(--white);
      padding: 0;
      width: 16px;
      height: 39px;
      border-radius: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      transition: all 0.3s ease-in-out;



      &:hover {
        transform: scale(1.1);
      }

      i {
        line-height: 2.6;
      }
    }
  }
}

// webkit scrollbar ends

// container
.container,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1260px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @media (max-width: 1440px) {
    max-width: 100vw;
  }
}

@mixin card-bg {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: var(--white-smoke);
  background: hsla(0deg, 0%, 100%, 0.98);
  border: 1px solid #efefef;
  -webkit-box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.01%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0%), 0 -2px 16px 0 rgba(0, 0, 0, 0.05%);
}

/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-input-affix-wrapper .ant-input:focus,
.ant-input-affix-wrapper .ant-input-focused {
  border: 0 !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #b9b9b9;
  box-shadow: none;
}

.ant-select-item.ant-select-item-option {
  color: var(--primary);

  &:hover,
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: var(--green);
    background-color: #f3f3f3;
  }
}

// radio button
.ant-radio-checked .ant-radio-inner,
.ant-radio-checked::after {
  border-color: var(--secondary);
}

// ant-switch-checked
.ant-switch-checked,
.ant-radio-inner::after {
  background-color: var(--secondary);
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

p {
  font: var(--font-paragraph);

  a {
    text-decoration: underline;
  }
}

a {
  color: var(--black);
}

a,
a:hover {
  color: var(--black);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  // font-family: HMSans, sans-serif;
  // font-family: 'Lato', sans-serif;
  // font-family: 'Roboto', sans-serif;
  font-family: var(--bold-font-family);

  // font-weight: 600;
}

p:last-child {
  margin-bottom: 0;
}

img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

hr {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d5d5d5;
  box-sizing: content-box;
  height: 0;
  margin: 16px 0;
  overflow: visible;
}

// font-size
.fs-15 {
  font-size: 15px;
}

// text-colors
.text-blue {
  color: var(--primary);
}

.text-black {
  color: var(--black);
}

.text-yellow {
  color: #ff9f00;
}

.text-gray {
  color: #898989;
}

.text-green {
  color: var(--secondary);
}

.text-underline {
  text-decoration: underline;
}

// font variation
.regular-font {
  font-family: var(--reglar-font-family);
}

.medium-font {
  font-weight: 500;
}

.bold-font {
  font-weight: 700;
}

.home-pagaragraph-body {
  font-size: 17px;
  line-height: 28px;
  word-break: break-word;
}

.paragraph-body {
  font-size: 1.125rem;
  line-height: 32px;
  margin: 0;
  word-break: break-word;

  @media(max-width:500px) {
    font-size: 16px;
    line-height: 27px;
  }
}

// backgrond color
.bg-dark-blue {
  background-color: var(--primary);
}

.bg-dark-gray {
  background-color: var(--dark-gray);
}

.bg-light-gray {
  background-color: var(--light-gray);
}

.bg-light-blue {
  background-color: #f4f8fb;
}

.bg-pannel-gray {
  background-color: var(--pannel-gray);
}

.bg-pantone-gray {
  background-color: var(--pantone-gray);
}

.bg-cool-gray {
  background-color: var(--cool-gray);
}

.bg-very-light-gray {
  background-color: var(--white-smoke);
}

.bg-light-pink {
  background-color: var(--light-pink);
}

.bg-warm-gray {
  background-color: var(--warm-gray);
}

.bg-grayish-red {
  background-color: var(--grayish-red);
}

.bg-light-grayish-pink {
  background-color: var(--light-grayish-pink);
}

.bg-black {
  background-color: var(--black);
}

.bg-layout-light-blue {
  background: var(--layout-light-blue);
}

.bg-gradient-color {
  background-image: var(--gradient-color);
}

.bg-light-green {
  background-color: var(--light-green);
}

.bg-shape-right {
  @include backgroundNoRepeat($position: top right, $size: contain);

  background-image: url("../assets/images/hm/shapes/blurb-shape.svg");
}

.bg-shape-wave {
  @include backgroundNoRepeat($position: top right, $size: contain);

  background-image: url("../assets/images/shape/wave.svg");
}

.bg-key-elements {
  @include backgroundNoRepeat($position: bottom left, $size: cover);

  background-image: url("../assets/images/hm/home/key-elements.jpg");

  @media(max-width: 400px) {
    padding-top: 300px;
  }
}

.bg-lightpink-shape {
  background-attachment: fixed;
  background-color: #f8dfd9;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpath fill='%23fae2dc' d='M486 705.8c-109.3-21.8-223.4-32.2-335.3-19.4C99.5 692.1 49 703 0 719.8V800h843.8c-115.9-33.2-230.8-68.1-347.6-92.2C492.8 707.1 489.4 706.5 486 705.8z'/%3E%3Cpath fill='%23fce5df' d='M1600 0H0v719.8c49-16.8 99.5-27.8 150.7-33.5c111.9-12.7 226-2.4 335.3 19.4c3.4 0.7 6.8 1.4 10.2 2c116.8 24 231.7 59 347.6 92.2H1600V0z'/%3E%3Cpath fill='%23fde8e2' d='M478.4 581c3.2 0.8 6.4 1.7 9.5 2.5c196.2 52.5 388.7 133.5 593.5 176.6c174.2 36.6 349.5 29.2 518.6-10.2V0H0v574.9c52.3-17.6 106.5-27.7 161.1-30.9C268.4 537.4 375.7 554.2 478.4 581z'/%3E%3Cpath fill='%23feece5' d='M0 0v429.4c55.6-18.4 113.5-27.3 171.4-27.7c102.8-0.8 203.2 22.7 299.3 54.5c3 1 5.9 2 8.9 3c183.6 62 365.7 146.1 562.4 192.1c186.7 43.7 376.3 34.4 557.9-12.6V0H0z'/%3E%3Cpath fill='%23FFEFE9' d='M181.8 259.4c98.2 6 191.9 35.2 281.3 72.1c2.8 1.1 5.5 2.3 8.3 3.4c171 71.6 342.7 158.5 531.3 207.7c198.8 51.8 403.4 40.8 597.3-14.8V0H0v283.2C59 263.6 120.6 255.7 181.8 259.4z'/%3E%3Cpath fill='%23fee9e2' d='M1600 0H0v136.3c62.3-20.9 127.7-27.5 192.2-19.2c93.6 12.1 180.5 47.7 263.3 89.6c2.6 1.3 5.1 2.6 7.7 3.9c158.4 81.1 319.7 170.9 500.3 223.2c210.5 61 430.8 49 636.6-16.6V0z'/%3E%3Cpath fill='%23fce3dd' d='M454.9 86.3C600.7 177 751.6 269.3 924.1 325c208.6 67.4 431.3 60.8 637.9-5.3c12.8-4.1 25.4-8.4 38.1-12.9V0H288.1c56 21.3 108.7 50.6 159.7 82C450.2 83.4 452.5 84.9 454.9 86.3z'/%3E%3Cpath fill='%23f9ddd7' d='M1600 0H498c118.1 85.8 243.5 164.5 386.8 216.2c191.8 69.2 400 74.7 595 21.1c40.8-11.2 81.1-25.2 120.3-41.7V0z'/%3E%3Cpath fill='%23f6d7d3' d='M1397.5 154.8c47.2-10.6 93.6-25.3 138.6-43.8c21.7-8.9 43-18.8 63.9-29.5V0H643.4c62.9 41.7 129.7 78.2 202.1 107.4C1020.4 178.1 1214.2 196.1 1397.5 154.8z'/%3E%3Cpath fill='%23F2D2CF' d='M1315.3 72.4c75.3-12.6 148.9-37.1 216.8-72.4h-723C966.8 71 1144.7 101 1315.3 72.4z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: cover;
}

.bg-vector-shape {
  background-image: url("../assets/images/vector-shape.webp");
  @include backgroundNoRepeat($position: top left, $size: contain);
}

.bg-logo-shape {
  position: relative;

  &::before {

    @include beforeContentLeft($background: url("../assets/images/shape/data-maretplace-placeholder.svg"), $opacity: 0.1);
    @include backgroundNoRepeat($repeat: repeat, $position: top left, $size: 28%);
  }
}

// content card design
.content-card {
  @include boxShadow;

  background: rgba(255, 255, 255, 0.99);
  padding: 45px;
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    padding: 20px;
  }
}

// section spacing
.spacing {
  padding-bottom: 50px;
  padding-top: 50px;

  @media(max-width: 600px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.spacing-3 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.space-4 {
  padding: 40px;
}

.space-2 {
  padding: 20px;
}

.top-spacing {
  padding-top: 57px;

  @media (max-width: 600px) {
    padding-top: 0;
  }
}

// Border radius
.border-radius {
  border-radius: 20px;
}

.border-top {
  border-top: 1px solid var(--chinese-silver);
}

.box-shadow {
  box-shadow: var(--default-box-shadow);
}

.ant-layout-header {
  // -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  // -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  // box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  height: auto;
  line-height: 1.5;
  z-index: 999;
}

.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.ant-layout-header .ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected {
  border-bottom: 2px solid var(--secondary);
  color: var(--white);
}

.ant-menu:not(.ant-menu-horizontal) {
  .ant-menu-item-selected {
    background-color: var(--color-shade8);
    color: var(--black);

    // .ant-menu-item-icon {
    //   background: var(--white);

    //   svg {
    //     color: var(--primary);
    //   }
    // }
  }

  // .ant-menu-item-icon {
  //   padding: 9px;
  //   background: var(--light-gray);
  //   border-radius: 7px;
  //   line-height: 1;
  // }
}

.ant-layout-header.fixed-header {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.default-spacing {
  margin-top: 54px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}


// Button styles starts=====
.btn {
  padding: 16px 32px;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 40px;
  border: none;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 17px;
  overflow: hidden;

  &:hover {
    -webkit-transition: 0.1s ease background-color;
    transition: 0.1s ease background-color;
  }
}

.btn.xs {
  font-size: 0.8125rem;
  padding: 8px 16px;
  font-weight: 300;
}

.btn.has-arrow {
  padding: 16px 32px 16px 31px;
}

.btn.invert {
  border-width: 1px;
  border-style: solid;
}

.btn.white {
  background-color: #ffffff;
  color: #333333;

  &.invert {
    color: #ffffff;
    background-color: transparent;
    border-color: #ffffff;
  }
}

.btn.dynex-iris {
  background-color: #6161FF;
  color: #ffffff;

  &:hover {
    background-color: #5151d5;
    -webkit-filter: unset;
    filter: unset;
  }
}

.signup-button {
  min-width: 130px;
}

// .btn-register
.ant-btn.btn-register {
  &::before {
    display: block !important;
    opacity: 1;
    top: 0;
    bottom: 0;
    right: unset;
    width: 12px;
    height: 12px;
  }

}

.btn-register {
  position: relative !important;
  padding: 9px 20px 9px 38px !important;
  height: auto !important;

  &:before {
    content: "" !important;
    box-shadow: 0 0 0 rgba(255, 255, 255, .4);
    animation: pulso 2s infinite;
    transition: .5s;
    width: 12px;
    height: 12px;
    border: 3px solid #fff;
    position: absolute;
    left: 15px;
    border-radius: 50%;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

// Animation
@keyframes pulso {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8)
  }

  70% {
    -moz-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0)
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0)
  }
}

a.standard,
button,
button.primary,
.ant-btn-primary,
.ant-btn-standard,
.ant-btn-secondary,
.ant-btn-button.secondary {
  border-radius: 3px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 400;
  line-height: -1;

  // height: 32px;
  outline: none;
  text-shadow: none;
  width: auto;
}

a.standard,
.ant-btn-primary {
  background: var(--primary);
  color: var(--full-black);
  font-weight: 500;

  &:hover,
  &:focus,
  &:active {
    background: var(--primary-hover);
  }
}

.ant-btn-standard,
.ant-btn-standard:hover,
.ant-btn-standard:focus {
  background: var(--secondary);
  border: 1.5px solid var(--secondary);
  color: var(--full-black);
}

.ant-btn-primary {
  border: 0 !important;
  color: var(--white);
}

.ant-btn-secondary {
  background: transparent;
  border: 1.5px solid var(--secondary);

  // position: relative;
  overflow: hidden;

  span,
  i {
    position: relative;
    z-index: 1;
  }

  &::after {
    background-color: var(--secondary);
    content: "";
    height: 100%;
    left: 0;
    left: -100%;
    opacity: unset;
    position: absolute;
    top: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    width: 100%;
    z-index: 0;
  }

  &:hover {
    &::after {
      left: 0;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: transparent;
    border-color: var(--secondary);
    color: var(--white);
    outline: none;
  }
}

.ant-btn {
  .anticon {
    position: relative;
    top: -3px;
  }
}

.ant-btn-icon-only>* {
  line-height: 2.4;
}

.ant-btn-sm {
  font-size: 11px;
  line-height: 2.2;

  .anticon {
    position: relative;
    top: 2px;
  }

  span.anticon {
    position: relative;
    top: -1px;
  }
}

a.standard,
.btn-glow.primary {
  background: var(--secondary);
  border: transparent;
  color: var(--full-black);
}

.ant-btn-primary:hover,
.btn-glow.primary:hover,
.ant-btn-primary:focus {
  border: 0 !important;
}

.btn-primary {
  background: var(--primary);

  // border: 1px solid var(--primary);
}

.btn-green {
  // background: #55d79f;
  background: var(--secondary);
  color: var(--full-black);
}

.btn-primary,
.btn-gradient {
  color: var(--white);
}

.btn-gradient {
  @include btnGradient;
}

.btn-secondary,
.ant-btn-button.secondary {
  background-color: var(--white);
  border-color: var(--primary) !important;
  color: var(--primary);

  &:hover {
    background-color: var(--primary);
  }
}

.btn-white {
  background: var(--white);
  border: 1px solid var(--white);
  color: var(--primary);
}

.btn-success {
  background: var(--green);
  border-color: var(--green);
}

.btn-large {
  border-radius: 3px;
  font-size: 16px;
  padding: 12px 31px;

  @media (max-width: 360px) {
    width: 100%;
  }
}

.btn-border-white {
  background-color: transparent;
  border: 1px solid var(--white);
  color: var(--white);
}

button i.no-margin {
  margin-right: 0;
}

// side menu changes starts

// filter layout
.filter-layout {
  .ant-layout-sider-collapsed {
    display: none;
    max-width: 0 !important;
    min-width: 0 !important;
    width: 0 !important;
  }
}

// side menu layout
.basic-filter-sider {

  @media(max-width: 500px) {
    #mobile-sider-menu {
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
    }
  }

  .ant-layout-sider,
  .ant-layout {
    max-height: calc(100vh - 120px);
    min-height: calc(100vh - 120px);

    @media(max-width: 900px) {
      max-height: 100%;
      min-height: 100%;

      .ant-layout-content {
        flex: none;
      }
    }

  }

  .ant-layout-sider {
    .filter-section {
      max-height: 100%;
      overflow-y: auto;

      @media(max-width: 500px) {
        height: 100vh;
      }
    }
  }

}

// Asset page side menu
.side-menu-layout {
  &.asset-sidebar {
    .asset-res-menu {
      display: none;
    }

    @media (max-width: 900px) {
      .filter-button {
        display: none;
      }

      .asset-res-menu {
        display: block;
        position: fixed;
        top: 40%;
        left: -5px;
        z-index: 1;
        padding: 4px;

        &.active {
          left: 315px;
          top: 40px;
          z-index: 9999;
          border-color: #fff;
          background: transparent;
          width: 40px;
          height: 40px;
          color: #fff;
        }
      }

      #mobile-sider-menu {
        display: none;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.57%);
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.38);

        .ant-layout-sider-children {
          width: 300px;
        }

        &.active {
          display: block;
        }

        &.ant-layout-sider-collapsed {
          .button-position {
            button {
              border-radius: 5px;
              height: auto;
              line-height: 2.4;
              width: 100%;

              i {
                margin-right: 10px;
              }

              span {
                display: inline-block;
              }
            }
          }

          .ant-menu {
            .ant-menu-item {
              width: 100%;

              .ant-menu-title-content {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    // @media (max-width: 900px) {
    //   #mobile-sider-menu {
    //     position: relative;
    //     z-index: 999;
    //     width: 100% !important;
    //     min-width: 100% !important;
    //     max-width: 100% !important;
    //     flex: 0 0 100% !important;
    //   }

    //   .ant-layout {
    //     &.ant-layout-has-sider {
    //       flex-direction: column;

    //       .ant-layout-sider-children {
    //         height: auto !important;

    //         .site-layout {
    //           min-height: auto;
    //         }
    //       }
    //     }
    //   }

    //   .ant-layout {
    //     &.site-layout {
    //       width: 100%;

    //       .filter-button {
    //         display: none;
    //       }
    //     }
    //   }

    //   // button-position
    //   .button-position {
    //     border-bottom: 0;
    //     padding: 0;
    //   }

    //   // ant-menu
    //   .ant-menu {
    //     padding: 0;
    //     display: flex;

    //     .ant-menu-item {
    //       width: 100%;

    //       .ant-menu-title-content {
    //         opacity: 1;
    //       }
    //     }
    //   }
    // }
  }

  .ant-menu {
    padding: 10px;
  }

  .button-position {
    border-bottom: 1px solid var(--border-color);
    padding: 21px 12px;
    text-align: center;

    button {
      font-size: 15px;
      min-height: 42px;
      line-height: 2.3;
      width: 100%;

      i {
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }

  .ant-layout-sider-collapsed {
    .ant-menu {
      padding: 7px;

      .ant-menu-item {
        padding: 6px 16px !important;
      }
    }

    .button-position {
      button {
        border-radius: 50%;
        height: 33px;
        line-height: 2.4;
        min-height: 33px !important;
        padding: 0;
        width: 33px;

        span {
          display: none;
        }

        i {
          margin-right: 0;
          position: relative;
          top: -1px;
        }
      }
    }
  }
}

.ant-layout-sider {
  background: linear-gradient(170.88deg, #fbfbfb 0%, #fbfbfb 100%);
  transform: translate3d(0, 0, 0);

  // box-shadow: 0 2px 10px 0 rgba(74, 74, 74, 0.5);
  // border-right: 1px solid #d3d3d3;

  .ant-layout-sider-children {
    background: var(--white);
    border-right: 1px solid var(--border-color);
    margin-top: -0.1px;
    padding-top: 0.1px;

    .ant-menu-vertical {
      border-right: 0;
    }
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background-color: transparent;
}

.ant-menu {
  font-size: 15px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 28px;
  line-height: 28px;
}

.main-header .ant-menu-submenu-title span {
  color: var(--white);
  font-size: 22px;
  font-weight: bold;
}

.brand-logo {
  background-color: #005da4 !important;
}

.brand-logo img {
  height: 35px;
}

.ant-menu li .icons {
  margin-right: 7px;
}

// side menu changes ends here

// container-fluid starts

.container-fluid {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

// container-fluid ends

// input fields starts
.ant-input:focus,
.ant-input-focused,
.ant-picker-focused,
.ant-picker-focused,
.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  @include antInputFocus;
}

input[type="text"],
input[type="password"],
input[type="number"],
input[type="date"],
.ant-picker,
select {
  border-radius: 3px;
  font-size: 14px;
  height: 38px;
  outline: none;
  padding: 6px 8px;
  position: relative;
  width: 100%;
}

.ant-input {
  border-radius: 3px;
}

input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

.ant-input-affix-wrapper {
  border-radius: 3px;
  height: 35px;
}

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.ant-select-selection-item {
  color: #262626;
}

.ant-select-selector {
  border-radius: 3px !important;
  height: 35px !important;
}

.ant-select.select-after.ant-select-single.ant-select-show-search {
  min-width: 40px !important;
}

.ant-input-affix-wrapper input[type="text"],
.ant-input-affix-wrapper input[type="password"] {
  height: auto;
}

.ant-checkbox-wrapper {
  font-size: 14px;
}

// input fields ends here

// header changes

.app-header {
  // background: linear-gradient(-47deg, rgba(1, 114, 235, 0.84) 0%, rgba(0, 90, 194, 0.92) 100%);
  // background: linear-gradient(185deg, rgba(1, 114, 235, 0.84) 0%, rgba(0, 90, 194, 0.92) 100%);
  // background-image: -webkit-linear-gradient(-47deg, rgba(88, 4, 20, 0.72) 0%, #c9002e 100%);
  // background-image: -o-linear-gradient(-47deg, rgba(88, 4, 20, 0.72) 0%, #c9002e 100%);
  // background-image: linear-gradient(-47deg, rgba(88, 4, 20, 0.72) 0%, #c9002e 100%);
  background: var(--dark-gray);

  // box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  border-radius: 0;
  box-shadow: none !important;
  color: var(--dark-gray);
  height: 54px;
  line-height: 52px;
  margin-bottom: 0;
  min-height: 54px;
  padding: 0 12px 0 0 !important;
}

.app-header::after {
  background-image: url("../assets/images/landing-page-background.png");
  content: "";
  height: 54px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.ant-layout-header .ant-menu {
  background: transparent;
}

// headder changes ends

// font size starts

.ant-tabs-tab,
.ant-tabs {
  font-size: 14px;
}

// font size ends

// Top menu section after login

.left-menu {
  float: left;
  width: 75%;

  .main-title {
    width: 100%;

    h1 {
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .search {
    margin-top: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.left-menu .left-menu .main-title .left-menu .action-icons {
  width: 100%;

  ul {
    display: inline-block;
    list-style: none;
    margin-bottom: 0;
    padding: 0 10px 0 0;
    width: 100%;

    li {
      display: inline-block;
      padding: 0 10px;

      a {
        line-height: 64px;
        padding: 6px;

        &.add-new {
          border: 1px solid #fbfbfb;
          border-radius: 5px;
          padding: 5px 20px;
        }
      }

      button {
        i {
          margin-right: 7px;
        }
      }
    }
  }
}

// Top menu section after login ends

// inner pages css starts
.breadcrum-title.without-bg {
  background-image: unset;
}

.breadcrum-title.only-heading {
  // background-image: url("../assets/images/accqrate-pattern-text-blue-half.svg");
  // background-position: 89% 100%;
  // background-repeat: no-repeat;
  // background-size: 51%;

  @media (max-width: 600px) {
    background-size: 80%;
  }
}

.breadcrum-title {
  background-color: #f5f5f5;

  // background-image: url("../assets/images/accqrate-pattern-text-gray-half.svg");
  // background-position: 10% 100%;
  // background-repeat: no-repeat;
  // background-size: 72%;
  float: left;
  overflow: hidden;
  padding-top: 40px;
  position: relative;
  width: 100%;

  h1 {
    color: var(--black);
    font-size: 30px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      font-size: 26px;
    }
  }

  img {
    display: block;
    height: 260px;
    margin: 0 auto;

    @media (max-width: 600px) {
      width: 95%;
    }
  }

  p {
    color: #575757;
    font-size: 19px;
  }

  .breadcrum-content {
    padding-bottom: 33px;

    @media (max-width: 780px) {
      padding-bottom: 20px;
    }
  }

  @media (max-width: 900px) {
    padding: 30px 0;
  }
}

.breadcrum-title.text-center {
  text-align: center;

  h1 {
    font-size: 37px;
    text-align: center;
  }
}

// order-breadcrumb

.order-breadcrumb {
  background: #f5f5f5;
  padding: 20px 0;
}

.inner-pages {
  background-color: var(--white);
  float: left;
  padding: 60px 0;
  width: 100%;

  p,
  ul {
    font-size: 16px;
    line-height: 181%;
  }

  h3 {
    font-size: 22px;
  }
}

.full-width-section {
  display: block;
  float: left;
  width: 100%;
}

// inner pages css ends

.inner-pages.about-us h1 {
  font-size: 25px;
  margin-bottom: 20px;
}

.inner-pages.about-us p {
  color: #000;
  font-size: 17px;
  line-height: 180%;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 27px;
  line-height: 28px;
}

// footer starts

.ant-layout-footer {
  background: var(--black);
  color: var(--white);
  padding: 16px 50px;
  z-index: 1;

  p {
    @media (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  p,
  ul li a {
    color: var(--white);
  }

  @media (max-width: 900px) {
    margin-top: 30px;
    padding: 30px 20px;
    text-align: center;
  }
}

.inner-contents {
  padding: 0 0 30px;

  .custom-background {
    padding: 0;
  }
}

.inner-contents .save-changes,
.fixed-action-area {
  @include card-bg;

  bottom: 0;
  left: 0;
  padding: 10px;
  position: fixed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.inner-contents .save-changes,
.fixed-action-area {
  z-index: 99;
}

.app-sidebar {
  position: relative;

  .ant-layout-sider {
    .ant-menu {
      background: transparent;
    }
  }
}

.app-sidebar .custom-table .custom-table-button .anticon {
  margin-left: 0;
}

.app-sidebar .custom-table .custom-table-button {
  border-radius: 4px;
  padding: 4px 11px;
}

// default input fields height

.filter-section .ant-select:not(.ant-select-auto-complete) .ant-select-selector,
.filter-section .ant-picker,
.filter-section .ant-input-affix-wrapper,
.filter-section .custom-input-box input {
  -webkit-appearance: none;
  background: transparent;
  background-color: var(--white);
  border: 1px solid var(--chinese-silver);
  border-radius: 3px !important;
  color: #737f8d;
  font-size: 12px;
  height: 29px !important;
  line-height: inherit;
  padding: 1px 7px 0;
  vertical-align: middle;
}

// default input fields height ends

.ant-select-multiple .ant-select-selector {
  height: auto !important;
}

.status.badge {
  border-radius: 10px;
  -webkit-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px -2px rgba(0, 0, 0, 0.75);
  color: var(--white);
  cursor: pointer;
  display: block;
  font-size: 82%;
  min-width: 100px;
  padding: 2px 10px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

.status.badge {

  &.pending,
  &.created {
    background: #ff9800;
    background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
    background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
    background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
  }
}

.status.badge.issued {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(51%, #f6290c),
      color-stop(71%, #f02f17),
      color-stop(100%, #e73827));
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
  background: linear-gradient(to bottom, #f85032 0%, #f16f5c 0%, #f6290c 51%, #f02f17 71%, #e73827 100%);
}

.status.badge.sent {
  background: #ffc107;
  background: -moz-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #ffc107),
      color-stop(0%, #ebb30b),
      color-stop(51%, #d5a411),
      color-stop(71%, #c29716),
      color-stop(100%, #b28b19));
  background: -webkit-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -o-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: -ms-linear-gradient(top, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
  background: linear-gradient(to bottom, #ffc107 0%, #ebb30b 0%, #d5a411 51%, #c29716 71%, #b28b19 100%);
}

.status.badge.partiallypaid {
  background: #f85032;
  background: -moz-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background:
    -webkit-gradient(left top,
      left bottom,
      color-stop(0%, #f85032),
      color-stop(0%, #f16f5c),
      color-stop(50%, #f6290c),
      color-stop(71%, #82bc40),
      color-stop(100%, #47761d));
  background: -webkit-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -o-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: -ms-linear-gradient(top, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
  background: linear-gradient(to right, #f85032 0%, #f16f5c 0%, #f6290c 50%, #82bc40 50%, #47761d 100%);
}

.status.badge.paid {
  background: #82bc40;
  background: -moz-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #82bc40), color-stop(100%, #47761d));
  background: -webkit-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -o-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: -ms-linear-gradient(top, #82bc40 0%, #47761d 100%);
  background: linear-gradient(to bottom, #82bc40 0%, #47761d 100%);
}

.status.badge.dropped {
  background: #ff9800;
  background: -moz-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, #ffb64a), color-stop(100%, #ff9800));
  background: -webkit-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -o-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: -ms-linear-gradient(top, #ffb64a 0%, #ff9800 100%);
  background: linear-gradient(to bottom, #ffb64a 0%, #ff9800 100%);
}

// status ends here

// button starts

.ant-table-cell .btn-group>.btn.glow.dropdown-toggle {
  height: 28px;
}

.btn-group,
.btn-group-vertical {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}

.btn-group>.btn.glow.dropdown-toggle {
  margin: 0 2px;
  padding: 6px 9px;
}

.btn-group>.btn.glow:hover {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
}

.btn-group button.glow:hover {
  color: #a8b5c7;
}

.btn-group button.glow:active,
.btn-group button.glow.active {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2) inset;
  color: #a8b5c7;
}

.btn {
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
  margin-bottom: 0;
  padding: 11px 22px;
  text-align: center;
  transition: all cubic-bezier(0.4, 0, 1, 1) 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
  }

  img {
    margin-right: 4px;
    margin-top: -3px;
    width: 17px;
  }
}

.btn-group>.btn.glow {
  border: 1px solid #d0dde9;
  color: #313d4c;
  float: left;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 6px 14px;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group button.glow {
  background: #fefefe;
  background: -moz-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, #fefefe),
      color-stop(100%, #f7f7f7));
  background: -webkit-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -o-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: -ms-linear-gradient(top, #fefefe 0%, #f7f7f7 100%);
  background: linear-gradient(to bottom, #fefefe 0%, #f7f7f7 100%);
  border: 1px solid #d0dde9;
  color: #313d4c;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr="#fefefe", endColorstr="#f7f7f7", GradientType=0);
  float: left;
  font-size: 11px;
  font-weight: 700;
  line-height: 14px;
  padding: 5px 10px;
  -moz-transition: color 0.1s linear;
  -webkit-transition: color 0.1s linear;
  -o-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.caret {
  border-bottom: 0 dotted;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  content: "";
  display: inline-block;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  width: 0;
}

.btn-glow {
  background: var(--white);
  background: -moz-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background:
    -webkit-gradient(linear,
      left top,
      left bottom,
      color-stop(0%, var(--white)),
      color-stop(100%, #eef0f1));
  background: -webkit-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: -o-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: -ms-linear-gradient(top, var(--white) 0%, #eef0f1 100%);
  background: linear-gradient(to bottom, var(--white) 0%, #eef0f1 100%);
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: var(--dark-gray);
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  padding: 5px 10px;
  text-align: center;
  vertical-align: middle;
}

.btn-block {
  display: block;
  width: 100% !important;
}

.btn-glow.primary {
  i {
    line-height: 10px;
    margin-right: 5px;
  }
}

a.btn-glow.primary {
  color: var(--white);
}

.btn-glow.success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: var(--white);
}

.btn-glow.delete-field {
  background: linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -moz-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -webkit-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -o-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  background: -ms-linear-gradient(to bottom, #f85032 0%, #e73827 100%);
  border: 1px solid #e11117;
  color: var(--white);
}

// button ends

// page not found starts
.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.page-not-found img {
  height: auto;
  margin-bottom: 18px;
  width: 130px;
}

.page-not-found h1 {
  font-size: 35px;
  font-weight: bold;
}

.page-not-found .btn-glow {
  font-size: 18px;
}

.page-not-found .btn-glow i {
  margin-right: 6px;
}

// page not found ends here

// loading content starts

.loading-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: center;
}

.loading-content img {
  height: auto;
  height: 50px;
  margin-bottom: 20px;
}

.loading-content h1 {
  font-size: 22px;
}

// loading content ends

.align-right {
  text-align: right;
}

.top-filter-options {
  h2 {
    @include HeadingStyle;

    @media (max-width: 900px) {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.content-container {
  padding: 0 14px;
}

.content-container .custom-background {
  padding: 0 !important;
}

// model for arrange column starts

.mobile-filter {
  display: none;
  left: 0;
  position: fixed;
  top: 40%;
  z-index: 999;

  @media (max-width: 900px) {
    display: none;
  }
}

.mobile-filter .btn-glow {
  padding: 7px 9px;
}

// add-new-invoice-button starts

.add-new-invoice-button {
  display: none;
  margin-top: 15px;
  width: 100%;

  @media (max-width: 900px) {
    display: block;
  }
}

// add-new-invoice-button ends

.ant-collapse {
  .ant-collapse-item {
    background-color: var(--secondary-background-color);
    border-radius: 8px;
    margin-top: 16px;
    --box-shadow-color: var(--application-border-color);
    box-shadow: 0px 3px 12px var(--box-shadow-color);

    .ant-collapse-header {
      font-family: var(--bold-font-family);
      font-size: 17px;
      font-weight: 600;
    }

    &:last-child {
      border-radius: 8px;
    }
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding: 21px;
    }
  }

  .ant-collapse-expand-icon {
    position: absolute;
    right: 3px;
    top: 6px;
  }
}

// add-proj-timesheet starts

.add-proj-timesheet {
  width: 100%;

  .form-fields {
    margin-bottom: 10px;

    label {
      display: inline-block;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }

  .add-proj {
    color: var(--primary);
    cursor: pointer;
    float: right;
    font-size: 15px;
    font-weight: bold;
    text-align: right;

    span {
      margin-right: 4px;
    }
  }

  #totalHours {
    // width: 110px;
    color: var(--primary);
    font-size: 20px;
    font-weight: bold;
    padding: 22px 20px;
  }
}

// form fields

.form-fields,
.ant-modal-content .form-field {
  margin-bottom: 13px;
}

.form-fields label,
.ant-modal-content .form-field label {
  font-weight: 600;
}

.ant-radio-group label {
  font-weight: normal;
}

.ant-modal-content .form-field label {
  font-size: 12px;
  margin-bottom: 3px;
}

// stop timer

.stop-timer {
  background: #d3d3d3;
  border-radius: 5px;
  color: var(--primary);
  cursor: move;
  display: none;
  padding: 0 10px;
  position: fixed;
  right: 0;
  text-align: center;
  top: 57px;
  width: fit-content;
  z-index: 9;

  .stop-icon {
    img {
      cursor: pointer;
      height: 27px;
      margin-left: 8px;
      margin-top: 0;
      width: auto;
    }
  }

  #time-count {
    font-size: 20px;
    font-weight: bold;
  }
}

.ant-tabs-nav .ant-tabs-tab-btn {
  font-size: 15px;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background: var(--secondary);
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--secondary);
}

.top-title-section {
  margin-bottom: 7px;
  margin-top: 23px;
  width: 100%;

  h2 {
    font-size: 19px;
  }
}

// ant steps
.ant-steps-item-icon {
  line-height: 28px;

  .ant-steps-icon {
    top: 0;
  }
}

.ant-steps-navigation .ant-steps-item::before,
.ant-steps-item-process .ant-steps-item-icon {
  background-color: var(--secondary);
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: var(--secondary);
}

// profile name

.profile-name {
  background: var(--primary);
  color: var(--white);
  text-align: center;

  span {
    font-weight: bold;
  }
}

// sider
#mobile-sider-menu {
  @media (max-width: 900px) {
    // display: none;
    position: absolute;
    z-index: 999;
  }
}

.required {
  color: #f44336;
  margin-left: -1px;
}

.ant-input[disabled],
.ant-picker-input>input[disabled] {
  color: #262626;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-input-number-handler-wrap {
  display: none !important;
}

.add-proj-timesheet {
  .submitted {
    color: var(--primary);
  }

  .approved {
    color: var(--success);
  }
}

// upload-invoice-expense starts

.upload-invoice-expense-note {
  background-color: var(--white);
  border: 1px solid #efefef;
  border-radius: 6px;
  box-shadow: 0 3px 6px 0 var(--cool-gray);
  font-size: 13px;
  line-height: 184%;
  margin-bottom: 30px;
  padding: 20px 24px;
  position: relative;
  width: 100%;

  .heading {
    border-bottom: 1px solid #f1f2f3;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 24px;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: -20px;
    padding: 10px 20px;
    position: relative;

    h2 {
      color: var(--black);
      font-size: 17px;
      margin-bottom: 0;
    }
  }
}

.upload-invoice-expense {
  .sample-template {
    margin-bottom: 15px;
    text-align: center;

    .ant-btn-primary {
      height: 32px;
    }
  }

  .custom-background {
    padding: 0;

    .custom-table {
      border: 1px solid var(--gray);
      border-radius: 5px;
      padding: 0 0 10px;
    }

    .card {
      @include mainCard($padding: 20px);
    }
  }
}

.custom-table {
  overflow: auto;

  thead>tr>td {
    @media (max-width: 900px) {
      min-width: 100px;
    }
  }

  @media (max-width: 900px) {
    border: 1px solid var(--light-shade-gray);
    overflow-x: auto;
  }
}

.ant-upload {
  width: 100% !important;
}

.fixed-action-area {
  text-align: center;

  button {
    margin: 0 5px;
  }
}

// upload field starts

.file-upload-field {
  background: #fbfbfb;
  border: 1px dashed #b3b3b3;
  border-radius: 5px;
  color: var(--black);
  cursor: pointer;
  padding: 20px;
  text-align: center;

  i {
    color: var(--secondary);
  }
}

// upload field ends

.list-field {
  border-top: 1px solid #f1f1f1;
  display: flex;
  margin-bottom: 3px;
  padding-bottom: 17px;
  padding-top: 5px;
  position: relative;

  >.ant-row {
    flex: 1;
  }

  .remove-column {
    .btn-glow.delete-field {
      padding: 0 4px;
    }
  }
}

.list-field:first-child {
  border-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  position: relative;
}

// Panel Layout starts
.panel-layout {
  margin-bottom: 10px;
  margin-top: 20px;

  h2 {
    font-size: 19px;
    margin-bottom: 6px;
  }

  .panel-title {
    color: var(--primary);
    font-size: 20px;
    margin-bottom: 14px;
    text-transform: uppercase;
  }

  .form-field {
    position: relative;

    label {
      color: var(--dark-gray);
      display: inline-block;
      font-size: 13px;
      margin-bottom: 3px;
      font-weight: 600;

      &:not(.ant-checkbox-wrapper) {
        width: 100%;
      }
    }
  }

  .panel-design {
    background-color: var(--white);
    margin-bottom: 25px;

    .panel-header {
      background-color: var(--alice-light-blue);
      border: 1px solid var(--light-shade-gray);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #000;
      display: block;
      margin-bottom: -1px;
      padding: 5px 15px;
      position: relative;

      >h3 {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .panel-body {
      background-color: var(--white);
      border: 1px solid var(--light-shade-gray);
      display: block;
      margin-bottom: -1px;
      padding: 10px 15px;
      position: relative;

      table {
        border: 1px solid var(--light-white-smoke);
      }
    }

    .panel-with-border {
      .ant-row {
        .ant-col {
          border-bottom: 1px solid var(--border-color);
          margin-bottom: 12px;
          padding-bottom: 12px;
        }

        .ant-col:last-child {
          border-bottom: 0;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }

      span {
        display: block;
        font-size: 13px;
      }

      p {
        font-weight: 600;
        margin-bottom: 0;
        margin-top: 4px;
      }

      p,
      .attachments-preview {
        font-size: 15px;
      }

      .attachments-preview {
        margin-top: 4px;
      }

      button {
        span {
          display: inline-block;
        }
      }
    }
  }
}

// save order table starts
.save-order-table {
  th {
    white-space: nowrap;
  }

  tr {

    td,
    th {
      padding: 0.75rem 0.3rem;
      vertical-align: middle;
    }
  }
}

.custom-loader-back,
.custom-loader,
.custom-loader-back-active {
  display: none;
}

.custom-loader-back-active {
  height: 100vh !important;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2147483647;
}

.custom-loader {
  // position: absolute;
  // top: 45px !important;
  top: 53px !important;
}

.table.borderless {

  td,
  th {
    border: 0;
  }
}

.ant-btn-success {
  background: #96bf48;
  border: 1px solid #99bd56;
  color: var(--white);

  &:active,
  &:hover,
  &:focus {
    background: darken($color: #99bd56, $amount: 8);
    border-color: darken($color: #99bd86, $amount: 8);
    color: var(--white);
  }
}

// design-holder starts

.designer-section {
  background: var(--white);
  margin: 15px auto;
  width: fit-content;

  .design-holder {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3;
  }
}

.header-text {
  color: var(--primary);
  font-weight: 600;
}

// social login starts

.social-login {
  margin-top: 20px;
  text-align: center;

  button {
    border: 0;
    border-radius: 3px;
    box-shadow: 0 2px 4px #d2d2d2;
    height: 37px;
    margin-right: 20px;
    width: 40%;

    img {
      height: 25px;
      margin-right: 6px;
      width: 25px;
    }
  }

  &:last-child {
    margin-right: 0;
  }
}

// ant-popover-inner-content action buttons

.ant-popover-inner-content .action-buttons {
  min-width: 100px;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      cursor: pointer;
      display: block;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.company-name {
  font-size: 17px;
  font-weight: bold;
  text-align: right;

  span {
    color: var(--light-blue);
    font-weight: normal;
  }
}

// custom-template-left starts

.custom-template-left,
.custom-template-right,
.custom-template-center {
  height: 91.7vh;
  overflow-y: auto;
}

.custom-template-center {
  overflow: auto;
  padding: 5px;
}

.custom-template-left {
  overflow-x: hidden;
  padding-right: 0 !important;

  ul.ant-menu {
    border-right: 0;

    li.ant-menu-submenu {
      border-bottom: 1px solid var(--light-gray);
      padding: 6px 0;
    }
  }
}

.custom-template-right {
  .template-controls {

    .footer-buttons,
    .top-save-button {
      background: var(--white);
      padding: 5px;
      position: fixed;
      text-align: center;
      width: 16.43%;
      z-index: 100;
    }

    .top-save-button {
      border-bottom: 1px solid #e9e9e9;

      button {
        width: 94%;
      }
    }

    .footer-buttons {
      border-top: 1px solid #e9e9e9;
      bottom: 0;

      button {
        background: transparent;
        border: transparent;
        color: var(--black);

        &:first-child {
          color: #f00;
        }

        &:last-child {
          color: var(--primary);
        }
      }
    }

    .template-name {
      font-size: 17px;
      font-weight: 600;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 50px;
    }

    .customizable-fields {
      margin-bottom: 40px;
      padding: 10px;

      .selected-item {
        margin-bottom: 10px;
      }

      .ant-divider .ant-divider-inner-text {
        color: var(--secondary);
        font-size: 14px;
        font-weight: 600;
      }

      .ant-divider-horizontal.ant-divider-with-text {
        margin: 8px 0;
      }

      .custom-input-box,
      .custom-select-box {
        margin-bottom: 5px;
      }

      .ant-col-lg-6,
      .ant-col-lg-12 {

        .ant-select-selector,
        input[type="text"],
        input[type="password"],
        input[type="number"],
        input[type="date"],
        .ant-picker,
        select {
          border-radius: 0 !important;
        }
      }

      .ant-select-selector,
      input[type="text"],
      input[type="password"],
      input[type="number"],
      input[type="date"],
      .ant-picker,
      select {
        border-radius: 2px !important;
        font-size: 12px !important;
        height: 31px !important;
        outline: none;
        padding: 5px 8px;
        position: relative;
        width: 100%;
      }

      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 21px;
      }
    }
  }
}

.wizard-section {
  @include card-bg;

  bottom: 0;
  display: flex;
  left: 0;
  padding: 10px 20px;
  position: fixed;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 999;

  .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item {
    padding-bottom: 4px;
  }

  .wizard-steps {
    padding-right: 20px;
    width: 60%;

    .ant-steps-item-title {
      font-size: 14px;
    }
  }

  .action-items {
    border-left: 1px solid var(--light-gray);
    padding-left: 20px;
    width: 40%;

    button {
      margin-right: 10px;
    }
  }
}

.ant-checkbox-wrapper.middle .ant-checkbox {
  top: 0;
}

.same-as-shipping-container {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: -29px;

  i {
    color: #a3a3a3;
  }
}

.field-array {
  display: flex;

  >.ant-space-item {
    flex: 1;

    .ant-skeleton-element {
      display: block;
      flex: 1;

      .ant-skeleton-input {
        flex: 1;
      }
    }
  }
}

.table-layout {
  .table-title {
    color: var(--primary);
    font-size: 20px;
    margin-top: 14px;
    text-transform: capitalize;
  }

  .add-button {
    display: none;
    margin-bottom: 15px;

    @media (max-width: 900px) {
      display: block;
    }
  }

  .custom-background {
    padding: 0;
  }
}

.terms-condition-content {
  // @extend #{".text-truncate"};

  max-width: 60ch;

  @media (max-width: 1024px) {
    max-width: 40ch;
  }

  @media (max-width: 768px) {
    max-width: 30ch;
  }
}

.custom-clearance {
  .customs-border {
    border-left: 15px solid var(--white);
  }

  .ant-table-cell.bg-white {
    background: var(--white) !important;
    padding-left: 20px !important;
  }
}

.custom-clearance,
.sales-quotations {
  .ant-btn-success[disabled] {
    background: #85a842 !important;
    border: 1px solid #6e8b37 !important;
    color: var(--white) !important;
    height: auto;
  }
}

.amount-words {
  color: gray;
  font-style: italic;
  margin-left: auto;
  width: 80%;
}

.filter-section {
  background: var(--white);

  // border-right: 1px solid #f1f1f1;
  // box-shadow: var(--shadow-depth-1);

  // max-height: 86vh;
  // height: 100%;
  // overflow-y: auto;
  padding: 12px 12px 66px;
  transition: all linear 1s;
  width: 100%;

  &:hover {
    opacity: 1;
    transition: all linear 1s;
  }

  .add-new-invoice {
    width: 100%;
  }

  form {
    margin-top: 0;

    .form-fields {
      margin-bottom: 8px;

      &:last-child {
        background: var(--white);
        border-top: 1px solid #f1f1f1;
        bottom: 0;
        display: flex;
        left: 0;
        margin-bottom: 0;
        margin-top: 0;
        min-height: 56px;
        padding: 10px;
        position: absolute;
        width: 99.7%;
      }

      &:last-child {
        button {
          margin: 0 5px;
          width: 100%;

          i {
            margin-right: 6px;
          }
        }
      }

      label {
        color: #5b5b5b;
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.2;
        margin-bottom: 2px;
        text-transform: capitalize;
      }

      .search {
        margin-top: 14px;
        width: 100%;
      }
    }
  }
}

ul {
  &.filter-buttons {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        color: var(--dark-gray);
        padding: 5px 10px;

        i {
          color: var(--caribbean-green);
          margin-right: 8px;
        }
      }
    }
  }
}

// Modal Popup Height setup starts

.ant-modal-content {
  .ant-modal-title {
    font-size: 21px;
    font-weight: 600;
  }
}

.ant-modal-body {
  padding: 17px;
  max-height: 75vh;
  min-height: 130px;
  overflow-y: auto;

  .links {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 #e0e0e0;
    padding: 20px;
    text-align: center;

    img {
      height: 45px;
      margin-bottom: 10px;
      width: auto;
    }
  }

  .links .links h4 {
    font-size: 15px;
    margin-bottom: 0;
  }

  form {
    .ant-col {
      position: initial;
    }
  }

  .ant-row {
    .ant-col:last-child {
      position: initial;
    }
  }

  .ant-modal-footer {
    position: absolute;
    bottom: -8px;
    background: #fff;
    left: 0px;
    border-radius: 0 0 16px 16px;
    padding-right: 20px !important;
  }
}

// Modal Popup Height setup ends here

// top-filter-options starts here

.top-filter-options {
  margin-bottom: 7px;
  margin-top: 23px;
  padding: 0 15px;
  width: 100%;

  h2 {
    font-size: 19px;
  }

  .exports-and-settings {
    display: none;
    width: 100%;

    ul {
      display: block;
      list-style: none;
      margin: 0;
      padding: 0;
      text-align: right;

      li {
        display: inline-block;
        padding: 0 6px;

        a {
          display: block;
        }
      }
    }
  }

  button {
    height: 31px;
    padding: 2px 12px;
  }
}

.app-sidebar {
  // height: calc(81vh);
}

.arrange-column {
  .action-item {
    margin-bottom: 20px;
    width: 100%;
  }
}

.income-container {
  padding: 0 14px;

  .custom-background {
    padding: 0 !important;
  }
}

.acc-range {
  align-self: center;
}

.invoice-section {
  background-color: var(--white);
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  h2 {
    font-size: 16px;
    margin-bottom: 6px;
  }

  h3 {
    font-size: 17px;
    margin-bottom: 0;
  }

  &.border-bottom {
    border-bottom: 1px sold #eee;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .form-field {
    position: relative;

    label {
      color: var(--black);
      display: inline-block;
      font-size: 11.5px;
      font-weight: 500;
      line-height: 21px;
      margin-bottom: 5px;
      text-transform: capitalize;

      &:not(.ant-checkbox-wrapper) {
        width: 100%;
      }
    }
  }

  label:not(.ant-checkbox-wrapper) span {
    color: #f44336;
    margin-left: 4px;
  }

  .buyer-seller-details {
    div {
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    div:last-child {
      margin-bottom: 0;
    }
  }

  .panel-design {
    position: relative;
    width: 100%;

    .list-group-item:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 16px;
    }
  }

  &.panel-design {
    .list-details {
      background: var(--alice-light-blue) !important;
      border-color: var(--light-shade-gray);
      color: #000;
    }

    .list-group-item {
      background-color: var(--white);
      border: 1px solid var(--light-shade-gray);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      display: block;
      margin-bottom: -1px;
      padding: 8px 15px;
      position: relative;

      h2 {
        margin-bottom: 0;
      }
    }
  }
}

.invoice-section {
  button {
    &.success {
      bottom: -15px;
      left: 13px;
      padding: 3px 6px;
      position: absolute;

      &.preview-changes {
        bottom: 0;
        left: unset;
        position: relative;
      }
    }
  }
}

.save-changes {
  display: block;
  text-align: center;
  width: 100%;

  button {
    margin: 0 10px;
  }
}

.line-item-footer {
  display: flex;
  float: right;
}

.line-item-footer-content {
  padding: 5px;
}

.invoice-attachment-container {
  padding: 25px 25px 25px 0;
}

.site-calendar-demo-card {
  border: 1px solid #261d1d;
  border-color: #026ec1;
  border-radius: 6px;
  margin: 11px;
  width: 300px;
}

.action-content {
  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    li {
      border-bottom: 1px solid var(--light-white-smoke);
      cursor: pointer;
      display: block;
      padding: 7px 0;

      a {
        border-bottom: 1px solid var(--light-gray);
        color: #444;
        display: block;
        padding: 5px 0;
        text-align: left;

        i {
          margin-right: 6px;
          position: relative;
          top: 2px;
        }
      }
    }

    &:last-child {
      a {
        border-bottom: 0;
      }
    }
  }
}

.widget-side-menu .ant-menu-item {
  height: 40px !important;
  line-height: 40px !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.definition-panel {
  // border-bottom: 1px solid #d3d3d3;
  // padding: 30px;

  .definition-title {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 9px;
  }

  .definition-value {
    font-size: 14px;
  }
}

// slick styles
// .slick-prev {
//   z-index: 9;

//   &::before {
//     background: url("../assets/images/hm/home/main-slider/arrow-left.svg") no-repeat;
//   }
// }

// .slick-next {
//   &::before {
//     background: url("../assets/images/hm/home/main-slider/arrow-right.svg") no-repeat;
//   }
// }

.slick-dots {
  bottom: 30px;
  opacity: 0.8;

  li {
    height: auto;
    margin: 0 3px;
    width: auto;

    button {
      background: unset;
      border: 2px solid;
      border-color: var(--white);
      border-radius: 10px;
      color: unset;
      cursor: pointer;
      display: block;
      font-size: 0;
      height: 9px;
      line-height: 0;
      outline: none;
      padding: 3px;
      width: 9px;

      &::before {
        display: none;
      }
    }

    &.slick-active {
      button {
        background: var(--primary);
        border-color: var(--primary);
        width: 20px;
      }
    }
  }
}

// Page title banner
.page-title-banner {
  position: relative;

  // &::before {
  //   @include beforeContentLeft($opacity: 0.3);
  // }

  &::after {
    @include beforeContentLeft($opacity: 1);

    background: linear-gradient(360deg, var(--primary) 13.68%, rgba(64, 75, 124, 0) 165.57%) !important;
  }

  .breadcrumb {
    padding-bottom: 10px;
    position: relative;
  }

  .breadcrumb,
  .breadcrumb-nav {
    span {
      display: inline-block;
      font-size: 12px;
      font-size: 13px;
      padding-right: 25px;
      position: relative;

      &::after {
        @include beforeContentLeft($content: "\002F", $top: -2px, $left: unset, $right: 0, $bottom: 0, $opacity: 1, $width: 16px, $height: 17px, $background: unset);
      }
    }

    span:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }

    &::after {
      @include beforeContentLeft($top: unset, $left: 0, $bottom: 0, $opacity: 1, $width: 35%, $background: unset);

      border-bottom: 2px solid transparent;
      border-image: linear-gradient(0.2turn, var(--white), rgba(0, 0, 0, 0));
      border-image-slice: 1;
      height: 2px;
    }
  }

  .breadcrumb-nav {
    padding-bottom: 0;

    span {
      &::after {
        @include beforeContentLeft($content: "›", $top: -2px, $left: unset, $right: 0, $bottom: 0, $opacity: 1, $width: 16px, $height: 17px, $background: unset);
      }

      a {
        color: var(--white);
      }
    }

    &::after {
      display: none;
    }
  }

  .title-content {
    max-width: 46%;

    h1 {
      font-size: 30px;

      @media(max-width: 600px) {
        font-size: 25px;
      }
    }

    p {
      font-size: 21px;

      @media(max-width: 600px) {
        font-size: 16px;
      }
    }
  }

  .breadcrumb,
  .title-content {
    position: relative;
    z-index: 1;
  }

  // banner images starts here
  &.edc-banner {
    background: url("../assets/images/hm/page-title/enterprise-data-council.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.notification-banner {
    background: url("../assets/images/hm/page-title/notification.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.services-banner {
    background: url("../assets/images/hm/page-title/services.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.calendar-banner {
    background: url("../assets/images/hm/page-title/calendar.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.aiad-banner {
    background: url("../assets/images/hm/page-title/aiad-townhall.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.faq-banner {
    background: url("../assets/images/hm/page-title/faq.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.products-banner {

    background: url("../assets/images/hm/page-title/products.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.latestnews-banner {
    background: url("../assets/images/hm/page-title/news.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.vision-banner {
    background: url("../assets/images/hm/page-title/vision.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.data-journey-banner {
    background: url("../assets/images/hm/page-title/data-journey.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.messagefrom-ceo-banner {
    background: url("../assets/images/hm/page-title/message-from-ceo.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.data-practice-banner {
    background: url("../assets/images/hm/page-title/data-practice.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.legacy-banner {
    background: url("../assets/images/hm/page-title/legacy.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.partners-banner {
    background: url("../assets/images/hm/page-title/partners.jpg");
    @include backgroundNoRepeat($position: center center);

  }

  &.knowledge-center-banner {
    background: url("../assets/images/hm/page-title/knowledge-center.jpg");
    @include backgroundNoRepeat($position: center center);
  }

  &.dashboard-banner {
    background: url("../assets/images/hm/page-title/value-demonstration.jpg");
    @include backgroundNoRepeat($position: center center);
  }
}

// title-layout
.title-layout {
  position: relative;
  @include dFlex($alignment: center);

  background-color: var(--primary);
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: inset 0 -1px 0 var(--border-color);

  // background-image: url('../assets/images/shape/dynex-title-shape.svg');
  // background: #e8e8e8;
  padding: 11px 24px;

  @media(max-width: 700px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .ant-btn {
    padding: 0px 8px;
    height: 28px;
    font-size: 13px;
    line-height: unset;
  }

  .ant-btn-secondary {
    background-color: var(--secondary);
    color: var(--full-black)
  }

  .inner-app-breadcrumb {
    a {
      color: var(--black);
      font-size: 14px;
    }
  }

  h1 {
    @include overfloweClips($width: 400px);
    line-height: 1;

    font-size: 17px;
    margin-bottom: 0;
    text-transform: capitalize;

    &.asset-title {
      margin-bottom: 4px;
    }
  }

  .title-content {
    @media(max-width: 700px) {
      max-width: 100%;
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .breadcrumb-nav {
    color: var(--white);
    font-size: 11px;

    span {
      &::before {
        content: "›";
        display: inline-block;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
      }

      a {
        @include overfloweClips($width: 112px);

        color: var(--white);
        display: inline-block;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    span:first-child {
      &::before {
        display: none;
      }
    }

    span:last-child {
      a {
        @include overfloweClips($width: 320px);

        display: inline-block;
      }
    }
  }

  // Asset Details
  .asset-details {
    padding-left: 65px;
    position: relative;

    .asset-icon {
      @include dFlex($alignment: center);

      background-color: var(--white);
      border-radius: 7px;
      height: 42px;
      left: 0;
      position: absolute;
      width: 51px;

      img {
        display: block;
        height: 19px;
        margin: 0 auto;
        display: none;
      }

      .default-icon {
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
        margin: 0 auto;
      }

    }


    .other-details {
      @media(max-width:450px) {
        flex-direction: column;
      }

      .asset-type,
      .asset-status {
        font-size: 12px;
        margin-right: 10px;

        span {
          display: inline-block;
          font-weight: normal;
          margin-right: 6px;
        }
      }
    }
  }
}

// help-faq section starts
.help-faq {
  h2 {
    font-size: 22px;

    @media(max-width: 600px) {
      font-size: 17px;
    }
  }

  p {
    font-size: 15px;
  }

  .ant-collapse {
    margin-bottom: 20px;
  }

  .ant-collapse>.ant-collapse-item {
    background: var(--white) !important;
    margin-top: 0;
  }

  .ant-collapse .ant-collapse-item:last-child {
    border-radius: 0;
  }

  .ant-collapse .ant-collapse-expand-icon {
    top: 31px;
  }

  .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
    padding: 15px 23px;
    display: block;
  }
}

// iframe-video
.iframe-video {
  height: 480px;
  min-width: 100%;
}

// number list
.number-list {
  ul {
    counter-reset: list-number;

    li {
      list-style: none;
      margin-bottom: 10px;
      padding-left: 20px;
      position: relative;

      &::before {
        align-items: center;
        background-color: var(--primary);
        border-radius: 50%;
        color: #fff;
        content: counter(list-number);
        counter-increment: list-number;
        display: inline-flex;
        font-size: 16px;
        height: 35px;
        justify-content: center;
        left: -30px;
        margin-bottom: 10px;
        margin-right: 10px;
        position: absolute;
        width: 35px;
      }
    }
  }
}

.list-spacing {
  ul {
    li {
      padding-bottom: 10px;
    }
  }
}

// our-focus list

.our-focus {
  h2 {
    font-size: 25px;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
  }

  .focus-lists {
    // @include dFlex ($alignment: "center");
    @include autoGridLayout;

    @media(max-width: 1050px) {
      @include autoGridLayout($size: 33.333%);
    }

    @media(max-width: 500px) {
      @include autoGridLayout($size: 50%);
    }

    @media(max-width: 330px) {
      @include autoGridLayout($size: 100%);
    }

    .list {
      padding: 9px;
      transition: all linear 0.3s;

      @media(max-width: 600px) {
        padding: 3px 3px;
      }

      &:nth-child(1) {
        a {
          .image {
            background-color: var(--grid-color1);
          }
        }
      }

      &:nth-child(2) {
        a {
          .image {
            background-color: var(--grid-color2);
          }
        }
      }

      &:nth-child(3) {
        a {
          .image {
            background-color: var(--grid-color3);
          }
        }
      }

      &:nth-child(4) {
        a {
          .image {
            background-color: var(--grid-color4);
          }
        }
      }

      a {
        border-radius: 15px;
        display: block;
        padding: 20px;

        @media(max-width: 600px) {
          padding: 12px;
        }

        .image {
          background-color: #e9e9e9;
          border-radius: 100px;
          height: 200px;
          margin: 0 auto;
          position: relative;
          width: 200px;

          @media(max-width: 600px) {
            width: 150px;
            height: 150px;
          }

          @media(max-width: 400px) {
            width: 120px;
            height: 120px;
          }

          &::before {
            border: 2px solid #ededed;
            border-radius: 50%;
            content: "";
            height: 217px;
            left: -9px;
            position: absolute;
            top: -8px;
            width: 217px;

            @media(max-width: 600px) {
              width: 167px;
              height: 167px;
            }

            @media(max-width: 400px) {
              width: 137px;
              height: 137px;
            }
          }

          &:hover {
            &::before {
              border-color: 2px solid var(--primary);
            }
          }

          img {
            max-height: 154px;
            position: relative;
            top: 21px;
            width: 100%;

            @media(max-width: 600px) {
              max-height: 121px;
            }

            @media(max-width: 400px) {
              max-height: 83px;
            }
          }
        }
      }
    }
  }

  &.icons {
    .focus-lists {
      .image {
        border-radius: 0%;
      }

      .list {
        img {
          border-radius: 0;
          margin-top: 14px;
          width: 78%;
        }
      }

      .title {
        p {
          font-size: 25px;
        }
      }
    }
  }
}

// profile dropdown menu style

// .main-header .search, ends here

.profile-main-menu {
  border-right: 0;
  width: 100%;

  a {
    color: #222;
    font-size: 16px;
    @include dFlex($alignment: center, $justify: flex-start);

    img {
      width: 25px;
      margin-right: 10px;
    }

    i {
      margin-right: 10px;
    }
  }

  a:hover {
    color: var(--primary);
  }

  li:last-child {

    a,
    a:hover {
      color: var(--primary);
    }
  }
}

// anticons
tr td .anticon svg {
  // margin-left: 3px;
  // margin-right: 8px;
  position: relative;
  top: -3px;
}

// app-sidebar


#mobile-sider-menu {
  .ant-menu {
    padding: 10px 0;
  }

  .anticon svg {
    font-size: 17px;
    position: relative;
    top: -4px;
  }



  .ant-menu-vertical .ant-menu-item:hover {
    border-right: 4px solid var(--primary);
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    border-right: 4px solid var(--secondary);
  }

  .ant-menu-vertical .ant-menu-item:hover {
    color: var(--secondary);
    background-color: #f3f3f3;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    // background-color: #cfd9e0;
    background-color: #f3f3f3;
    color: var(--primary);
  }

  .ant-menu-inline-collapsed {
    .ant-menu-item-selected {
      border-right-width: 4px;
    }
  }

  .ant-menu-item {
    font-size: 15px;
    color: var(--primary);
    margin-bottom: 6px;
    height: 39px;
    padding: 3px 30px;
    line-height: 2;

    .ant-menu-item-icon {
      height: 22px;
    }
  }

}

// card
.card {
  @include mainCard($padding: 20px);
}

.section-component {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: var(--secondary-background-color);
  border-radius: 8px;
  margin-top: 16px;
  --box-shadow-color: var(--application-border-color);
  box-shadow: 0px 3px 12px var(--box-shadow-color);
}

.second-card {
  @include secondCard($padding: 24px 29px);

  transition: 0.4s;
}

// profile Navigation starts
.profile-side-navigation {
  ul {
    list-style: none;
    padding: 0;

    li {
      display: inline-block;

      a {
        color: var(--black);
        display: block;
        font-size: 16px;
        padding: 0 10px;
        position: relative;
        text-align: center;
      }

      a.active {
        color: var(--primary);

        &::before {
          @include beforeContentLeft($top: 40px, $width: 100%, $height: 3px, $background: var(--primary), $opacity: 1);
        }
      }

      a:hover {
        color: var(--primary);
      }
    }
  }
}

// profile Navigation ends



// custom-tab-view
.custom-tab-view {
  @include dFlex($alignment: center, $justify: space-between);

  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 24px;
}

// button-sec
.button-sec {
  .icon {
    margin-right: 4px;
    position: relative;
    top: 2px;
  }

  @media(max-width: 600px) {
    display: none;
  }

  &.active {
    display: block;
    position: absolute;
    top: 45px;
    right: 21px;
    background: var(--white);
    padding: 20px;
    border-radius: 9px;
    z-index: 9;
    --box-shadow-color: var(--application-border-color);
    box-shadow: 0px 3px 12px var(--box-shadow-color);

    .ant-space-item {
      .ant-space {
        display: block;

        .ant-space-item {
          width: 100%;
          margin-bottom: 10px;

          button {
            width: 100%;
            background: transparent;
            border: transparent;
            text-align: left;

            &:hover,
            &:focus,
            &:active {
              background: transparent;
              border: transparent;
            }
          }
        }
      }
    }
  }
}

.align-right {
  @include dFlex($justify: flex-end);
}

.display-flex {
  @include dFlex($alignment: center);
}

.display-grid {
  display: grid;

  /* 250px for the first column, auto for the second */
  grid-gap: 10px;
  grid-template-columns: 250px auto;

  /* optional gap between columns */
}

// show-asset-action-buttons
.show-asset-action-buttons {
  display: none;

  @media(max-width: 600px) {
    display: block;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 16px;
    color: var(--white);
    font-size: 20px;
  }
}

// image paragaraph alignment
.img-para {
  padding-left: 122px;
  position: relative;

  img {
    height: 93px;
    left: 0;
    position: absolute;
    top: 0;
  }
}

// stepper
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: var(--primary);
  border-color: var(--primary);
}

// .ant-select-dropdown
.ant-select-dropdown {
  min-width: 120px !important;
}

// Rich text editor
.cke_chrome {
  @include BorderRadius($radius: 5px);

  border: 1px solid var(--border-color) !important;
  display: block;
  padding: 0;

  .cke_inner {
    @include BorderRadius($radius: 5px);
  }

  .cke_top {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active {
      @include BorderRadius($radius: 4px);

      background: var(--pannel-header);
      border: 1px var(--pannel-header) solid;
      padding: 3px 5px;
    }
  }

  .cke_bottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .cke_top,
  .cke_bottom {
    background: var(--white) !important;
    border-color: var(--border-color) !important;
  }
}

// dialog box
.cke_dialog_title {
  background: var(--white) !important;
}

.cke_dialog {
  .ImagePreviewBox {
    padding: 13px;
  }
}

.cke_dialog_container {

  .cke_dialog_ui_labeled_content,
  .cke_dialog_ui_input_text,
  .cke_dialog_ui_input_select,
  .cke_dialog_ui_select {
    width: 100% !important;
  }

  input[type="text"],
  input[type="password"],
  input[type="number"],
  input[type="date"],
  .ant-picker,
  select {
    height: 32px !important;

    &:focus {
      border: 1px solid #bcbcbc !important;
    }
  }
}

.cke_dialog_contents_body .cke_dialog_ui_text,
.cke_dialog_contents_body .cke_dialog_ui_select,
.cke_dialog_contents_body .cke_dialog_ui_hbox_last>a.cke_dialog_ui_button {
  margin-top: 8px !important;
}

// Documentation styles =============
.documentation-side-menu {
  background: var(--primary);
  padding: 20px 11px;
  height: 100%;

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    padding: 0 8px;
  }

  .ant-menu .ant-menu-submenu-inline div.ant-menu-submenu-title {
    padding-left: 10px !important;
  }

  .ant-menu .ant-menu-submenu ul.ant-menu-inline .ant-menu-item {
    padding-left: 20px !important;
  }

  ul.ant-menu {
    font-size: 14px;

    li.ant-menu-submenu {
      border-bottom: 1px solid #8e999f26;
      padding-top: 7px;
      padding-bottom: 8px;
    }
  }

  // document search
  .search-documentations {
    position: relative;

    input[type="text"] {
      border-radius: 20px;
      height: 33px;
      border-color: var(--white);
    }

    .search-button {
      cursor: pointer;
      position: absolute;
      top: 0;
      right: 0px;

      i {
        @include circleButton($width: 33px, $height: 33px, $fontSize: 12px, $lineHeight: 2.8);
      }
    }
  }
}

.cta-bg {
  background: linear-gradient(90deg, #f2fff0 0%, #c2dfed 100%);
  border-radius: 20px;
  padding: 30px 40px;

  @media(max-width: 600px) {
    padding: 25px;
  }
}

.ant-radio-button-wrapper {
  &:hover {
    color: var(--secondary);
  }
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: var(--secondary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: var(--secondary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--secondary);
}

// features
.features {
  counter-reset: my-sec-counter;

  &.heigth-min {
    .grid-list {
      min-height: 196px;

      @media(max-width: 900px) {
        min-height: auto;
      }
    }
  }

  &.heigth-1 {
    .grid-list {
      min-height: 214px;

      @media(max-width: 900px) {
        min-height: auto;
      }
    }
  }

  &.heigth-2 {
    .grid-list {
      min-height: 283px;

      @media(max-width: 900px) {
        min-height: auto;
      }
    }
  }

  &.heigth-3 {
    .grid-list {
      min-height: 146px;

      @media(max-width: 900px) {
        min-height: auto;
      }
    }
  }

  &.heigth-4 {
    .grid-list {
      min-height: 208px;

      @media(max-width: 900px) {
        min-height: auto;
      }
    }
  }

  .display-grid {
    @include gridDesign($size: 16%, $gap: 10px);

    @media(max-width: 900px) {
      @include gridDesign($size: 33.333%, $gap: 10px);
    }

    @media(max-width: 600px) {
      @include gridDesign($size: 50%, $gap: 10px);
    }

    @media(max-width: 350px) {
      @include gridDesign($size: 100%, $gap: 0);
    }


    .icon {
      height: 60px;
      margin-bottom: 10px;
    }
  }

  .ant-row {
    .ant-col {
      .grid-list {
        position: relative;
        text-align: left;

        @media(max-width: 576px) {
          margin-bottom: 15px;
        }

        &::after {
          @include counterNumber($fontSize: 76px, $color: #eeffeb, $bgcolor: unset, $webkitFill: unset);
        }

        p {
          position: relative;
          z-index: 9;
        }

        .icon {
          height: 45px;
          width: 45px;
          margin-bottom: 10px;
          text-align: left;
        }
      }
    }
  }

  // colored list
  &.colored {

    .ant-row,
    .display-grid {

      .ant-col,
      .list {
        .grid-list {
          background-color: #dee2ff;
        }
      }

      .list:nth-child(even),
      .ant-col:nth-child(even) {
        .grid-list {
          background-color: #e6ffc9;
        }
      }
    }
  }

  // colored list
  &.colored-1 {
    .ant-row {
      .ant-col {
        .grid-list {
          background-color: #faeeee;
        }
      }

      .ant-col:nth-child(even) {
        .grid-list {
          background-color: #f1f1f1;
        }
      }
    }
  }
}

// key-features 
.key-features {
  .list {
    min-height: 343px;

    @media(max-width: 500px) {
      min-height: auto;
    }

    h3 {
      font-size: 31px !important;
    }
  }
}

// Animations //////////
// move down
.move-down {
  position: absolute;
  bottom: -9px;
  right: 0;
  left: 0;
  z-index: 2;
  animation: 1s linear 4s infinite alternate moveupdown;

  img {
    height: 40px;
    cursor: pointer;
  }
}

@keyframes moveupdown {
  from {
    transform: translateY(-20px);
  }

  to {
    transform: translateX(0);
  }
}

// images size deinition
.steps-articulation {
  img {
    width: 54%;
    margin: 0 auto;
    display: block;
  }
}

// asset icon update
.ant-upload.ant-upload-select-picture-card {
  height: 73px;
}

.home-page,
.data-marketplace {
  .ant-select-dropdown {
    border-radius: 10px;
  }
}

// print screen page break after always

.pint-page-break {
  page-break-before: always
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }
}

// Hide cursor after selecting input
.ant-select {
  &.ant-select-focused {
    caret-color: transparent;
  }

  &.ant-select-open {
    caret-color: black;
  }
}

// partners 
.partners {
  @media(max-width: 600px) {
    img {
      margin-top: 10px;
    }
  }
}

.questions {
  img {
    @media(max-width: 600px) {
      height: 100px;
    }
  }
}